import Image from 'next/image';
import React, { FC } from 'react';
import Link from 'next/link';

type Props = {
    rewardCount: number;
};

const RewardBlock: FC<Props> = ({ rewardCount = 0 }) => {
    // parse float to int
    const rewardCountInt = Math.floor(rewardCount / 10);

    return (
        <div className="flex flex-col gap-y-2 items-center">
            <div className="px-12 text-center text-xs">
                <p>Get HK$60 RHKYC credit for every 30 charging hours</p>
                <p>
                    *Rewards will be credited to your RHKYC member account
                    within 1 month.
                </p>
            </div>

            <div className="border rounded bg-white">
                <div className="grid grid-cols-12 items-center">
                    <div className="col-span-8">
                        <div className="flex flex-wrap gap-2 w-36 py-5 pl-2">
                            {[...Array(rewardCountInt).keys()].map((i) => (
                                <Image
                                    key={i}
                                    width={20}
                                    height={19}
                                    alt={`batter-${i}`}
                                    src="/battery.svg"
                                />
                            ))}
                            {[...Array(10 - rewardCountInt).keys()].map((i) => (
                                <Image
                                    key={i}
                                    width={20}
                                    height={19}
                                    alt={`batter-empty-${i}`}
                                    src="/battery-empty.png"
                                />
                            ))}
                        </div>
                    </div>
                    <div className="col-span-1 text-center">=</div>
                    <div className="col-span-3 flex flex-col justify-center items-center">
                        <Image
                            alt="gift-open"
                            src="/ev-logo.svg"
                            height={30}
                            width={30}
                        />
                        {/* <p className="text-sm ml-1">{giftCount}</p> */}
                        <p className="text-base ml-1">$60</p>
                        <p className="text-xs ml-1">Credit</p>
                    </div>
                </div>
            </div>

            {/* <Link href="/redeem">
                <button
                    className="py-2 px-[4.7rem] disabled:opacity-50 w-fit border border-black rounded-lg uppercase mt-2"
                    type="button"
                >
                    CLICK TO REDEEM
                </button>
            </Link> */}
        </div>
    );
};

export default RewardBlock;
